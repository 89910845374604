<template>
  <div style="height: 100%">
    <!-- 语音播报部分 -->
    <broadcast ref="broadcast" />
    <component :is="ChartComponent"></component>
  </div>
</template>

<script>
import Broadcast from '@/components/Broadcast'

export default {
  name: 'ChatRender',
  computed: {
    uiMode () {
      return this.$store.state.uiMode
    },
    ChartComponent () {
      if (this.uiMode === 1) {
        return () => import('./Chat.vue')
      } else {
        return () => import('./ChatSimple.vue')
      }
    }
  },
  components: {
    Broadcast
  },
  watch: {},
  methods: {
    visibilityChangeHandler () {
      if (document.hidden) {
        this.$refs.broadcast.stop()
      } else {
        this.$refs.broadcast.play()
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$refs.broadcast.stop()
    next()
  },
  mounted () {
    // 监听显示隐藏事件
    window.addEventListener(
      'visibilitychange',
      (e) => {
        this.visibilityChangeHandler()
      },
      true
    )
    this.evtBus.$on('broadcastStop', () => {
      this.$refs.broadcast.pause()
    })

    var unwatch = this.$watch(
      'uiMode',
      (val) => {
        const modeChangeEmitter = new CustomEvent('modeChanged', {
          detail: val
        })
        window.dispatchEvent(modeChangeEmitter)
        unwatch && unwatch()
      },
      {
        immediate: true
      }
    )

    this.$watch('uiMode', (val) => {
      const modeChangeEmitter = new CustomEvent('modeChanged', {
        detail: val
      })
      window.dispatchEvent(modeChangeEmitter)
      this.$store.commit('clearCacheData')
      this.$refs.broadcast.pause()
    })
  },
  beforeMount () {
    window.removeEventListener('visibilitychange', (e) => {
      this.visibilityChangeHandler()
    })
  },
  beforeDestroy () {
    this.$refs.broadcast.pause()
  }
}
</script>

<style>
.unknown-component {
  display: none !important;
}
</style>
